<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			首页管理<span>&gt;</span>
			LOGO
			<button class="veadmin-iframe-button" onclick="location.reload();">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<table style="width:400px;margin-bottom:10px;margin-top:20px;margin-left:20px;">
				<tr>
					<td style="width: 94px;margin-bottom: 15px;vertical-align: text-bottom;">
						<label class="el-form-item__label" style="width: 100%;">轮播图</label>
					</td>
					<td>
						<el-upload
							class="upload-demo"
							:action="this.apiServer + '/api/v1/admin/logo/add_or_update'"
							:auto-upload="false"
							:limit="1"
							:on-preview="handlePreview"
							:on-remove="handleRemove"
							:on-exceed="handleExceed"
							:on-success="onSuccess"
							:on-error="onError"
							:before-upload="beforeUpload"
							:file-list="fileList"
							:data="postForm"
							:headers="header"
							name="png"
							ref="elUpload"
							list-type="picture"
							accept=".png"
						>
							<el-button size="small" type="primary">选择本地图片</el-button>
							<div slot="tip" class="el-upload__tip">只能上传png文件，且不超过500KB</div>
						</el-upload>
					</td>
				</tr>
			</table>

			<el-dialog :visible.sync="dialogVisible">
				<img width="100%" :src="dialogImageUrl" alt="" />
			</el-dialog>

			<el-form :model="postForm" status-icon ref="postForm" label-width="100px" style="width:400px;margin-left:20px;">
				<el-form-item label="图片链接" prop="image_url">
					<el-input v-model="postForm.image_url" placeholder="图片链接"></el-input>
				</el-form-item>
				<el-form-item label="格言" prop="motto">
					<el-input v-model="postForm.motto" placeholder="格言"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="note">
					<el-input type="textarea" v-model="postForm.note" placeholder="备注"></el-input>
				</el-form-item>
				<el-button type="primary" style="margin-left:100px;" @click="saveLogo">保存</el-button>
			</el-form>
		</div>
	</div>
</template>

<script>
import { apiUpdateLogo } from "@/api/admin/logo.js";
import { apiGetLogo } from "@/api/pub/logo.js";
import base from "@/base.js";

export default {
	data() {
		return {
			apiServer: base.apiServer,
			submitElUpload: false,
			postForm: {
				id: "",
				image_url: "",
				motto: "",
				note: ""
			},
			header: { token: this.$store.state.token },
			fileList: [],
			dialogImageUrl: "",
			dialogVisible: false
		};
	},
	methods: {
		handleRemove(file, fileList) {
			this.submitElUpload = true;
			//console.log("handleRemove fileList>>", fileList);
			//console.log("handleRemove this.fileList>>", this.fileList);
		},
		handlePreview(file) {
			//console.log("handlePreview>>", file.url);
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleExceed() {
			this.$message.error("只能上传一张照片，请先删除之前选择的照片");
		},
		beforeUpload(file) {
			if (file.size > 500 * 1024) {
				this.$message.error("图片大小不能超过500KB!");
				return false;
			} else if (file.type != "image/png") {
				console.log(file);
				this.$message.error("只能上传.png格式的图片文件");
				return false;
			} else {
				return true;
			}
		},
		// 图片上传成功
		onSuccess: function(response) {
			//console.log(response);
			if (response.code === 200) {
				this.$message({
					message: "保存成功",
					type: "success"
				});
				//执行成功1秒后刷新页面
				setTimeout(function() {
					location.reload();
				}, 1000);
			} else if (response.code == -100) {
				this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "error"
				})
					.then(() => {
						//跳转到登录页面（路由跳转则滑动验证会失效）
						//this.$router.push({ path: "/login?target=" + window.location.href });
						window.location.href = "/login?target=" + window.location.href;
					})
					.catch(() => {
						this.$message({
							type: "warning",
							message: "已取消"
						});
					});
			} else {
				console.log("response>>", response);
				this.$message.error(response.message);
			}
		},
		onError: function(response) {
			console.log("response>>", response);
			this.$message.error(response.message);
		},
		saveLogo() {
			//console.log(this.postForm);
			if (this.submitElUpload) {
				//需要上传图片文件
				this.$refs.elUpload.submit();
			} else {
				//不需要上传图片文件
				let formData = new FormData();
				formData.append("id", this.postForm.id);
				formData.append("image_url", this.postForm.image_url);
				formData.append("motto", this.postForm.motto);
				formData.append("note", this.postForm.note);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				apiUpdateLogo(this.$store.state.token, formData).then((response) => {
					if (response.data.code == 200) {
						this.$message({ message: "更新成功", type: "success" });
						//执行成功1秒后刷新页面
						setTimeout(function() {
							location.reload();
						}, 1000);
					} else if (response.data.code == -100) {
						this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "error"
						})
							.then(() => {
								//跳转到登录页面（路由跳转则滑动验证会失效）
								//this.$router.push({ path: "/login?target=" + window.location.href });
								window.location.href = "/login?target=" + window.location.href;
							})
							.catch(() => {
								this.$message({
									type: "warning",
									message: "已取消"
								});
							});
					} else {
						console.log("response>>", response);
						this.$message.error(response.data.message);
					}
				});
			}
		},
		async getLogo() {
			try {
				const response = await apiGetLogo();
				//console.log(response);
				if (response.data.code == 200) {
					let logo = response.data.data;
					if (logo == null) {
						this.submitElUpload = true;
					} else {
						this.submitElUpload = false;
						this.postForm.id = logo.id;
						this.postForm.image_url = logo.image_url;
						this.postForm.motto = logo.motto;
						this.postForm.note = logo.note;
						this.fileList = [{ name: "", url: this.apiServer + logo.image_url }];
					}
				} else {
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		this.getLogo();
	}
};
</script>
<style lang="scss">
/* element样式重置 start */
/* 去掉input尾部上下小箭头 start */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	-o-appearance: none !important;
	-ms-appearance: none !important;
	appearance: none !important;
	margin: 0;
}
input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	-o-appearance: textfield;
	-ms-appearance: textfield;
	appearance: textfield;
}
/* 去掉input尾部上下小箭头 end */
</style>
<style lang="scss" scoped>
.upload-demo {
	width: 297px;
}
</style>
